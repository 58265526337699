import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Link } from "@reach/router";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import List from "../../../components/custom-widgets/list";
import HelpSection from "../../../components/home-loans/help-section";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const EasyMoveCrossCollateral = () => {
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/easy-move-cross-collateral/hero-cross-collateral-12272022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "easy-move-cross-collateral-hero",
    ...getHeroImgVariables(imgData),
    altText: "Family moving day with dad holding a cardboard moving box and mom holding baby.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Upgrading to a New Home Should Be Easy"
          },
          subheading: {
            class: "text-white",
            text: "Our Easy Move Cross Collateral home loan makes it possible"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Easy Move Cross Collateral"
    }
  ];

  const SEOData = {
    title: "Easy Move Cross Collateral Home Loan",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Easy Move Cross Collateral Home Loan"
      },
      {
        name: "description",
        content:
          "Explore how the Easy Move Cross Collateral Loan lets you stay in your current home while you close on your new home."
      },
      {
        property: "og:title",
        content: "Easy Move Cross Collateral Home Loan"
      },
      {
        property: "og:description",
        content:
          "Explore how the Easy Move Cross Collateral Loan lets you stay in your current home while you close on your new home."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/easy-move-cross-collateral"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-cross-collateral-12272022.jpg"
      }
    ]
  };

  const easyMoveBenefits = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Purchasing power to make a competitive offer on a new home"
      },
      {
        id: 2,
        text: "Ability to buy or build your dream home before your current home is sold"
      },
      {
        id: 3,
        text: "Avoid the hassle of contingency loans"
      },
      {
        id: 4,
        text: "No pressure to sell your old home right away so you can get the best offer"
      },
      {
        id: 5,
        text: "No moving twice&mdash;Move right in to your new home"
      },
      {
        id: 6,
        text: "No hassle or pressure of having to constantly show your home while you are living in it"
      }
    ]
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you've done all your research but aren't sure whether refinancing makes sense, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const preApprovalData = {
    title: "Easy Move Cross Collateral with a Pre-Approval*"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <div className="row">
          <div className="col-12">
            <h1>Easy Move Cross Collateral Home Loan</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <p className="h4">
              Buy your new home and worry about selling your current house later with an Easy Move Cross Collateral Home
              Loan. Depending on how much equity* you have in your current home, you could get into your new home for as
              little as <span className="text-green-60 font-weight-bolder">zero down</span>.
            </p>
            <p className="h4">
              After the sale of your current home, you apply the proceeds to reduce your Easy Move Cross Collateral
              loan**. Best of all? You can get prequalified so you can get your search started and find the home that's
              right for you.
            </p>
            <p className="h4 text-green-60 font-weight-bolder">
              Ready to get prequalified? Meet with a WaFd Mortgage Banker to get started today.
            </p>
            <button class="btn-primary btn" id="mortgage-apply-now-link" onClick={handleShowLOModal}>
              Apply Now
            </button>
            <LoanOfficerSearch
              isModalSearch={true}
              show={showLOModal}
              loanType="Home Loan"
              handleClose={handleCloseLOModal}
            />
          </div>
          <div className="col-md-6 col-lg-6">
            <p className="font-weight-bold mb-3">Easy Move benefits:</p>
            <List {...easyMoveBenefits} accountName={"easy-move-benefits"} />
          </div>
        </div>
      </section>
      <PreApprovalWorkflow {...preApprovalData} />

      <section className="bg-gray-10">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <StaticImage
                src="../../../images/how-to-buy-another-house-600-2x.jpg"
                alt="Family in front of their house"
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2 className="h3">How to Buy Another House Before Selling Yours</h2>
              <p>
                Learn how an Easy Move Cross Collateral Loan, available through WaFd Bank, lets you stay in your current
                home while you close on your new home.
              </p>
              <Link
                to="/blog/home-ownership/easy-move-cross-collateral"
                className="btn btn-primary w-100 mx-auto w-md-auto no-min-width"
                id="ss-read-article-btn"
              >
                Read Article
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="container section-padding">
        <p id="easy-move-cross-collateral-disclaimer-1" className="text-muted text-sm">
          *The equity of your home is the difference between the value of your home and how much you owe on your
          mortgage. If your home is worth $350,000 and you owe $150,000 on your mortgage, your equity is $350,000 minus
          $150,000 or $200,000.
        </p>
        <p id="easy-move-cross-collateral-disclaimer-2" className="text-muted text-sm">
          **The Easy Move Cross collateral loan is calculated based on a maximum 75% combined loan to value (CLTV) up to
          $1,500,000 less any existing mortgage owed on your current home. For example, when if the current home is
          valued at $350,000 with an existing mortgage of $150,000 and the new home is priced at $400,000, the Easy Move
          Cross Collateral loan is calculated as follows:
        </p>
        <p id="easy-move-cross-collateral-disclaimer-3" className="text-muted text-sm">
          The combined value of your current home and new home is $350,000 plus $400,000 or $750,000. The CLTV (75%) of
          the combined value of $750,000 is $562,500.
        </p>
        <p id="easy-move-cross-collateral-disclaimer-4" className="text-muted text-sm">
          The Easy Move Cross Collateral loan is $562,500 minus the existing mortgage of $150,000 which equals $412,500.
          The Easy Move Cross Collateral loan will finance the purchase price plus closing costs as long as the total is
          less than $412,500 without requiring the contingency of selling your current home.
        </p>
        <p id="easy-move-cross-collateral-disclaimer-5" className="text-muted text-sm">
          Easy Move Cross Collateral Loan Program requires the current home and the new home located in the same state.
        </p>
        <p id="easy-move-cross-collateral-disclaimer-6" className="text-muted text-sm mb-0">
          Easy Move Cross Collateral Loan program is not available in Texas.
        </p>
      </div>
      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default EasyMoveCrossCollateral;
